import React, { useRef, useEffect, useState } from "react"
import PropTypes from "prop-types"
import "./section.scss"
import { motion } from "framer-motion"
//import { useScrollYPosition } from "react-use-scroll-position"

function Section(props) {
  const sectionRef = useRef()
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const { y } = sectionRef.current.getBoundingClientRect()
      const enterPos = window.innerHeight - 200

      if (y <= enterPos) {
        setVisible(true)
      }
    })
    return () => {
      window.removeEventListener("scroll", () => {
        console.log("remove")
      })
    }
  })

  return (
    <section id={props.id} ref={sectionRef}>
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={visible && { opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className={`${props.className || "content"}`}
      >
        <h2>{props.title}</h2>
        <div>{props.children}</div>
      </motion.div>
    </section>
  )
}

Section.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default Section
