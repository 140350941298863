import React from "react"
import PropTypes from "prop-types"
import "./hero.scss"
import { useSpring, animated } from "react-spring"

function Header(props) {
  const titleSpring = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 600,
  })
  const subtitleSpring = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 1800,
  })
  const ctaSpring = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 1800,
  })
  return (
    <>
      <div className="hero">
        <svg
          className="defs-only"
          style={{ visibility: "hidden", position: "absolute" }}
        >
          <filter
            colorInterpolationFilters="srgb"
            x="0"
            y="0"
            height="100%"
            width="100%"
            id="duotone"
          >
            <feColorMatrix
              type="matrix"
              values="0.66796875 0 0 0 0.2265625 0.6953125 0 0 0 0.1796875 0.65234375 0 0 0 0.1875 0 0 0 1 0"
            />
          </filter>
        </svg>

        <img className="image" src={props.image} alt={`${props.title}`} />
        <div className="overlay" />
        <div className="hero-inner">
          <animated.h1 style={titleSpring}>{props.title}</animated.h1>
          {props.subtitle && (
            <animated.p style={subtitleSpring}>{props.subtitle}</animated.p>
          )}
          {props.cta && (
            <animated.a style={ctaSpring} href={props.href}>
              <button onClick={props.onClick} className="btn">
                {props.cta}
              </button>
            </animated.a>
          )}
        </div>
      </div>
    </>
  )
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  cta: PropTypes.string,
  onClick: PropTypes.func,
  href: PropTypes.string,
}

export default Header
