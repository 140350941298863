import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Section from "../components/section"
import Form from "../components/form"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero
      image="https://images.unsplash.com/photo-1499237302743-ba2c2740f824?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1649&q=80"
      title="Family is what matters most."
      subtitle="I am here to fight for yours."
      cta="Learn More"
      href="#about"
    />
    <Section id="about" title="About Cynthia">
      <p>
        Cynthia, the daughter of an attorney, and has been involved in the legal
        profession all her life. She was admitted to the Bar in 1979 after
        graduation from the Bowen School of Law, University of Arkansas at
        Little Rock. She, therefore, brings more than forty years of personal
        experience to her practice. During those decades of law practice, she
        has developed a zeal for helping families in distress or who face
        decisions concerning their loved ones; therefore, for over twenty years
        now, her practice has largely consisted of family law cases.
      </p>
      <h4>What Cynthia brings to her clients are:</h4>
      <ul>
        <li>Legal expertise and competence </li>
        <li>Ease of practice before the Courts borne of long experience</li>
        <li>
          Compassion and empathy for her clients in an area of the law chosen by
          her according to her passion.
        </li>
      </ul>
    </Section>
    <Section id="family-law" title="Family Law">
      <ul>
        <li>Divorces</li>
        <li>Separate Maintenance</li>
        <li>Paternity cases</li>
        <li>Child Custody</li>
        <li>Child support</li>
        <li>Guardianships</li>
        <li>Adoptions</li>
        <li>Wills and Trusts</li>
        <li>Attorney ad litem services</li>
      </ul>
    </Section>
    <Section id="aal" title="Attorney Ad Litem Representation">
      <p>
        A particular passion for her is representation of children as
        Court-appointed attorney ad litem. In these cases, Cynthia represents
        her child clients just as their parents' attorneys represent the adults.
        As attorney ad litem she investigates, reports and recommends to the
        Court on the best interests of the children involved. These services are
        also available for disabled adults.
      </p>
      <h4>Attorney ad litem services are for:</h4>
      <ul>
        <li>Children in custody/visitation disputes </li>
        <li>Children in abusive situations </li>
        <li>Children in Guardianship and Adoption cases</li>
        <li>
          Disabled adults in cases where their voice needs to be heard. These
          could include guardianships, civil lawsuits{" "}
        </li>
      </ul>
    </Section>
    <Section id="contact" title="Contact Cythia">
      <p>
        If you are in need of legal representation, please use the form below to
        contact her. She will be in contact with you as soon as possible.
      </p>
      <Form />
    </Section>
  </Layout>
)

export default IndexPage
