import React, { useState } from "react"
import "./form.scss"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

function Form() {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [subject, setSubject] = useState("")
  const [message, setCaseDetails] = useState("")
  const formData = { name, email, phone, subject, message }
  const isValid =
    name !== "" &&
    email !== "" &&
    phone !== "" &&
    subject !== "" &&
    message !== ""
      ? true
      : false
  const [submitted, setSubmitted] = useState(false)
  const handleSubmit = e => {
    e.preventDefault()
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...formData }),
    })
      .then(() => setSubmitted(true))
      .catch(error => alert("Something went wrong. Please try again, or call"))
  }
  return (
    <div className="form-wrap">
      {submitted ? (
        <div className="message">
          <h3>You're information has been received.</h3>
          <p>Thank you for contacting me. I will be in touch with you soon.</p>
        </div>
      ) : (
        <form
          method="post"
          name="contact"
          netlify-honeypot="bot-field"
          data-netlify="true"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="bot-field" value="contact" />
          <label>
            Name*
            <input
              required
              type="text"
              name="name"
              id="name"
              value={name}
              onChange={e => {
                setName(e.target.value)
              }}
            />
          </label>
          <label>
            Email*
            <input
              required
              type="email"
              name="email"
              id="email"
              value={email}
              onChange={e => {
                setEmail(e.target.value)
              }}
            />
          </label>
          <label>
            Phone*
            <input
              required
              type="phone"
              name="phone"
              id="phone"
              value={phone}
              onChange={e => {
                setPhone(e.target.value)
              }}
            />
          </label>
          <label>
            Subject*
            <input
              required
              type="text"
              name="subject"
              id="subject"
              value={subject}
              onChange={e => {
                setSubject(e.target.value)
              }}
            />
          </label>
          <label>
            Case Details*
            <textarea
              required
              name="message"
              id="message"
              rows="5"
              value={message}
              onChange={e => {
                setCaseDetails(e.target.value)
              }}
            />
          </label>
          <button disabled={!isValid} type="submit">
            Send
          </button>
        </form>
      )}
    </div>
  )
}

export default Form
